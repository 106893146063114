import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IQuoteBlockProps } from './model';
import './TextBlock.scss';

const TextBlock: FC<IQuoteBlockProps> = ({ text }) => (
  <DangerouslySetInnerHtml className="text-block" element="div" html={text} />
);

export const query = graphql`
  fragment FragmentTextBlock on TTextBlockStructure {
    structure
    properties {
      content
      maxWidth
      color
    }
  }
`;

export default TextBlock;
